
import Component, { ComponentMethods } from "@/module/component/component"

class LinkView extends ComponentMethods implements ComponentEntity {
    public title: string = "链接"

    public src: string = null

    onLoad(param: obj) {
        this.src = param.path
    }
}

export default Component.build(new LinkView())
